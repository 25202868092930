$chart-red: #FB404B;
$chart-blue: #1DC7EA;
$chart-orange: #FFA534;
.ct-chart{
  .pie-peace{
    path{
      stroke: #ffffff;
    }
    &_red{
      path{
        fill: $chart-red;
      }
    }
    &_blue{
      path{
        fill: $chart-blue;
      }
    }
    &_orange{
      path{
        fill: $chart-orange;
      }
    }
    &_empty{
      fill: none;
    }
  }
  .ct-label{
    fill: #ffffff;
    font-size: 1.3rem!important;
    font-weight: 600;
  }
}
.chart-legend{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &__item{
    padding: 5px;
    flex-grow: 1;
    display: inline-flex;
    align-items: center;
    &:before{
      content: '';
      min-width: 10px;
      max-width: 10px;
      min-height: 10px;
      max-height: 10px;
      border-radius: 50%;
      background-color: $chart-red;
      margin-right: 5px;
    }
    &_red{
      &:before{
        background-color: $chart-red;
      }
    }
    &_blue{
      &:before{
        background-color: $chart-blue;
      }
    }
    &_orange{
      &:before{
        background-color: $chart-orange;
      }
    }
  }
}
