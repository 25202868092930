.schedule__invites-table {
  td {
    padding: 3px;
  }
}
.schedule__history-table {
  overflow-y: auto;
  //height: 300px;

  .history-item {
    border-bottom: 1px solid #d0d0d0;
    padding-bottom:4px;
    margin-bottom:5px;
    .history-time {
      color: #909090;
    }
    .history-comment {
      padding-left:5px;
    }
  }
}
.schedule__daywrapper {

  display: flex;

  .schedule__daytitle {
    width:15%;
  }
  &.filled {
    .schedule__daytitle {
      font-weight: bold;
    }
  }
  &.sunday {
    margin-bottom:15px;
    border-bottom: 1px solid #D0D0D0;
    padding-bottom: 10px;
  }

  .schedule__itemswrapper {
    width: 85%;
    border-bottom: 1px solid #f0f0f0;
  }

  .schedule__item {
    background: #80C5F6;
    color: white;
    padding:5px 5px 5px 15px;
    border-left: 3px solid #517C99;
    margin-bottom: 5px;
    width:100%;
    display: flex;
    &.schedule__item--vacation {
      background: #FA9E95;
      border-left: 3px solid #B3716B;
    }
    .title {
      padding-left: 15px;
    }
    .action {
      margin-left:auto;
      margin-right: 10px;
      border: 1px solid white;
      border-radius: 3px;
      padding-left:10px;
      padding-right: 10px;
      color: white;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
    .shift {
      width: 100%;
      display: flex;
    }
  }

}

.header2-text-label {
  width: 150px;
  display: inline-block;
  text-align: center;
  min-width: 150px;
}
