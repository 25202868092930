.react-calendar {
  width: 350px;
  padding: 5px;
  max-width: 100%;
  background: white;
  // border: 1px solid rgb(160, 160, 150);
  border: 1px solid #e8e8e8;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;

  &--doubleView {
    width: 700px;

    .react-calendar__viewContainer {
      display: flex;
      /*margin: -@include 5em;*/

      > * {
        width: 50%;
        /*margin: @include 5em;*/
      }
    }
  }

  &, & *, & *:before, & *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  button {
    margin: 0;
    border: 0;
    outline: none;

    &:enabled {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__navigation {
    height: 44px;
    margin-bottom: 1em;

    button {
      min-width: 44px;
      background: none;

      // &:enabled {
      //   &:hover, &:focus {
      //     background-color: rgb(230, 230, 230);
      //   }
      // }

      &[disabled] {
        background-color: rgb(240, 240, 240);
      }
    }
  }

  &__month-view {
    &__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      /*font-size: @include 75em;*/

      &__weekday {
        font-size: 11px;
        border-bottom: none !important;
        abbr[title]{
          text-decoration: none;
          font-family: Roboto;
           border-bottom: none !important;
        }
        /*padding: @include 5em;*/
      }
    }

    &__weekNumbers {
      font-weight: bold;
      .react-calendar__tile {
        display: flex;
        align-items: center;
        justify-content: center;
        /*font-size: @include 75em;*/
        height: 25px;
        line-height: 25px;
      }
    }

    &__days {
      &__day {
        color:#455997;
        &--weekend {
          color: rgb(209, 0, 0) !important;
        }

        &--neighboringMonth {
           color: rgb(117, 117, 117)
          
        }
        &--setCircle {
          border:solid 3px #2b7bff !important;
          //border-radius: 10px;
          background-color: #488dff !important;
          color: #fff !important;
          line-height: 20px !important;
          // abbr{
          //   background-color: #488dff !important;
          // }
        }
      }
    }
  }

  &__year-view,
  &__decade-view,
  &__century-view {
    .react-calendar__tile {
      /*padding: 2em @include 5em;*/
      height: 25px;
      line-height: 25px;
    }
  }

  &__tile {
    max-width: 100%;
    text-align: center;
    /*padding: .75em @include 5em;*/
    background: none;
    height: 25px;
    line-height: 25px;

    &:disabled {
      background-color: rgb(240, 240, 240);
    }

    &:enabled {
      &:hover, &:focus {
        background-color: rgb(230, 230, 230);
      }
    }

    &--now {
      $bgcolor: lighten(rgb(220, 220, 0), 30%);
      background: $bgcolor;

      &:enabled {
        &:hover, &:focus {
          background: lighten($bgcolor, 10%);
        }
      }
    }

    &--hasActive {
      $bgcolor: lighten(rgb(0, 110, 220), 30%);
      background: $bgcolor;

      &:enabled {
        &:hover, &:focus {
          background: lighten($bgcolor, 10%);
        }
      }
    }

    &--active {
      $bgcolor: rgb(0, 110, 220);
      background: $bgcolor;
      color: white;

      &:enabled {
        &:hover, &:focus {
          background: lighten($bgcolor, 10%);
        }
      }
    }
  }

  &--selectRange {
    .react-calendar__tile {
      &--hover {
        background-color: rgb(230, 230, 230);
      }
    }
  }
}

.day-week-selected-from {
  // background: #1fc6ea;
  // background-color: #488dff;
  // opacity: 0.1;
  background-color: rgba(72,141,255,0.1);
  color: #455997;
}
.day-week-selected-to {
  // background: #be6fef;
  background-color: rgba(41,168,121,0.4);
}
.schedule-resource-selection {
  a {
    cursor: pointer;
  }
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
}
