.conversation-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  color: #447df7;
}
.conversation-list-item-current {
  background: #f0f0f0;
}

.conversation-action {
  position: absolute;
  right: 10px;
  color:#447df7;
  font-size:16px;
}
.conversation-list-item:hover {
  background: #D0D0D0;
  cursor: pointer;
}

.conversation-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  font-size:46px;
}

.conversation-title {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
}

.conversation-snippet {
  font-size: 14px;
  color: #888;
  margin: 0;
}

.conversation-badge {
  margin-left: auto;
  background: darkred;
  color:white;
  font-weight: bold;
  border-radius: 14px;
  line-height:14px;
  width:26px;
  text-align: center;
  padding:3px;
}
