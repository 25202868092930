@font-face {
  font-family: "Open Sans";
  font-weight: 400;
  src: url("../../assets/fonts/OpenSans/OpenSans-Regular.ttf");
}
@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  src: url("../../assets/fonts/OpenSans/OpenSans-SemiBold.ttf");
}
