.message {
  display: flex;
  flex-direction: column;
}

.message .timestamp {
  display: flex;
  justify-content: center;
  color: #999;
  font-weight: 600;
  font-size: 12px;
  margin: 10px 0px;
  text-transform: uppercase;
}

.message .bubble-container {
  font-size: 14px;
  display: flex;
}

.message.mine .bubble-container {
  justify-content: space-between;
}

.message.start .bubble-container .bubble {
  /* margin-top: 10px; */
  border-top-left-radius: 20px;
}

.message.end .bubble-container .bubble {
  border-bottom-left-radius: 20px;
  /* margin-bottom: 10px; */
}

.message.mine.start .bubble-container .bubble {
  margin-top: 10px;
  border-top-right-radius: 20px;
}

.message.mine.end .bubble-container .bubble {
  border-bottom-right-radius: 20px;
  margin-bottom: 10px;
}

.message .bubble-container .bubble {
  margin: 1px 0px;
  background: #f4f4f8;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 75%;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.message.mine .bubble-container .bubble {
  background: #007aff;
  color: white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.message .bubble-container .avatar-container {
  width:40px;
  height: 40px;
  display: flex;
}

.avatar-wrapper .user-title {
  white-space: nowrap;
  width: 40px;
  font-size: 9px;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  line-height: 9px;
  display: block;
  z-index: 100;
  cursor: default;
  margin-top: -9px;
}
