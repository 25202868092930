.dashboard-project-panel {
    background: white;
    padding: 10px;
}
.totals.project .label{
    background: #744098;
}
.totals.hours .label{
    background: #FF1E50;
}
.totals.project .value {
    color: #744098;
}
.totals.hours .value {
    color: #FF1E50;
}
.totals .label {
    color: white;
    width: 250px;
    min-width: 250px;
    display: inline-block;
    line-height: 25px;
    height:25px;
    margin-bottom: 10px;
    margin-right: 20px;
}

.totals .value {
    font-weight: bold;
    display: inline-block;

}
