.profile-edit-form{
  &.primary-page{
    padding: 20px;
  }
  .tab-content{
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid #ddd;
    border-top: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  &__group{
    padding: 10px 0;
    border-radius: 5px;
    border: 1px solid #dddddd;
    margin: 10px 0;
    .form-subtitle{
      padding: 0 15px 10px 15px;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .react-select__control {
    .react-select__value-container {
      flex-wrap: nowrap !important;
    }
    .react-select__multi-value {
      min-width: 70px;
    }
  }
}
.add-user-form {
  width: 50%;
  .error {
    color: #b31e2d;
  }
}
