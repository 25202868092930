.marker-cluster{
  display: flex;
  align-items: center;
  justify-content: center;
  div{
    width: 20px;
    height: 20px;
    background-color: #447df7;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    span{
      color: #ffffff;
      font-size: 10px;
      font-weight: 900;
    }
  }
}
.custom-marker{
  display: flex;
  align-items: flex-end;
  justify-content: center;
  outline: none;
  &:hover{
    .custom-marker__icon{
      &:after{
        opacity: 0.7;
      }
    }
  }
  &__icon{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 50%;
    height: 50%;
    margin-bottom: 5%;
    border-radius: 50%;
    background-color: #447df7;
    border-bottom-left-radius: 0;
    transform: rotate(-45deg);
    &:after{
      content: '';
      position: absolute;
      top: 15%;
      right: 15%;
      left: 15%;
      width: 70%;
      height: 70%;
      background-color: white;
      border-radius: 50%;
      opacity: 0;
      transition: 0.3s ease-in-out;
    }
    &_active{
      &:after{
        opacity: 1;
      }
    }
  }
}
.project-map{
  height: 400px;
}
.leaflet-popup{
  .leaflet-popup-content-wrapper{
    border-radius: 4px;
    .leaflet-popup-content{
      margin: 0;
      padding: 10px 15px;
    }
  }
  a.leaflet-popup-close-button{
    font-size: 13px;
    padding: 3px;
    box-sizing: content-box;
    height: 12px;
    width: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}
