.notificationdock--wrapper {
  display: flex;
  padding-left:10px;
  padding-right:10px;
  flex-flow: wrap;

  .notificationdock--close {
    font-size:18px;
    margin-left:auto;
    color: #808080;
    padding: 10px;
    cursor: pointer;
  }
  h2 {
  }
  .flex-break {
    flex-basis: 100%;
    height: 0;
  }
  .notifications--list{
    display: block;
    width: 100%;
    .clear-button {
      margin-left: auto;
    }
  }
}
