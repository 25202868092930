.radio.with-color label::before, .radio.with-color label::after{
  color: inherit!important;
}
.btn-tablet{
  font-size: 16px;
  font-weight: 900;
  min-width: 200px;
  padding: 5px 15px;
  min-height: 50px;
}
