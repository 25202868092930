
.modal{
    display: block !important; /* I added this to see the modal, you don't need this */
}

/* Important part */
.banner-view .modal-dialog{
    overflow-y: initial !important
}
.modal-body{
    height: 80vh;
    overflow-y: auto;
    margin: 0.5em;
}
.ql-editor {
  min-height: 120px;
  padding: 0px;
}

.ql-editor p > img{
  width:100%;
  height:100%;
}

.ql-snow .ql-tooltip {
  /* display: none !important; */
  top:50% !important;
  left:35% !important;
}

.status_read{
  color: #26b852;
}

.status_unread{
  color: #000;
}

/* width */

::-webkit-scrollbar {

    width: 6px;
  
    margin: 0 15px;
  
  }
  
  
  /* Track */
  
  ::-webkit-scrollbar-track {
  
    box-shadow: inset 0 0 5px #dbe5ed;
  
    border-radius: 10px;  
  
  }
  
  /* Handle */
  
  ::-webkit-scrollbar-thumb {
  
    background-color: grey;
  
    border-radius: 10px;
  
  }

