.custom-form{
  display: flex;
  width: 100%;
  flex-direction: column;
  &__row{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    &_v-center{
      align-items: center;
    }
    &_v-end{
      align-items: flex-end;
    }
    &_h-center{
      justify-content: center;
    }
    &_h-end{
      justify-content: flex-end;
    }
  }
  &__col{
    padding: 10px;
    box-sizing: border-box;
    display: block;
    flex-grow: 1;
    margin: 0;
    flex-basis: 1px;
    min-width: 200px;
    &_v-center{
      align-self: center;
    }
    &_v-end{
      align-self: flex-end;
    }
    &_h-center{
      justify-self: center;
    }
    &_h-end{
      justify-self: flex-end;
    }
    &_z-max{
      z-index: 10;
    }
    &_grow-0{
      flex-grow: 0;
      min-width: auto;
    }
    &_sep-right{
      position: relative;
      &:before{
        content: '';
        position: absolute;
        display: block;
        height: 60%;
        border-radius: 2px;
        top: 20%;
        width: 2px;
        background-color: #447df7;
        right: 0;

      }
    }
  }
  &__row-label{
    width: 100%;
    display: inline-block;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0.45px;
    color: #818181;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
  }
  .accordion{
    &__panel{
      padding: 5px 0;
    }
  }
  .input-block, .input-area{
    padding-top: 0;
    margin-bottom: 0;
  }
  &.modal-body{
    padding: 5px;
  }
}
