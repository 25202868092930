.table-project-small{
  tbody{
    .table-project-small__tasklink {
      border-radius: 5px;
      background: #a9b7bf;
    }
    .table-project-small__row{
      transition: 0.3s ease-in-out;
      cursor: pointer;
      a{
        color: #333333;
        font-weight: 900;
        display: inline-flex;
        width: 100%;
        justify-content: center;
        &:hover{
          text-decoration: underline;
        }
      }
      &:hover{
        background-color: #c5d4f5;
      }
      &_active{
        color: #ffffff;
        background-color: #447df7!important;
        a{
          color: #ffffff;
        }
      }
    }
  }
}

// Schedules
.scheduler{
  & > tbody{
    tr{
      border: none;
      td{
        border: none;
        .resource-view{
          position: relative;
          border: none;
          border-right: 2px solid #447df7;
         // z-index: 200;
          overflow: hidden;
          div{
            border: none;
            overflow: hidden;
            table{
              thead{
                tr{
                  th{
                    border: none!important;;
                  }
                }
              }
            }
            .resource-table{
              .resource-row{
                background-color: #ffffff;
                span.slot-text{
                  color: #777777;
                }
                a.slot-text{
                  color: #447df7;
                  cursor: pointer;
                  &:hover{
                    text-decoration: underline;
                  }
                }
                &.pending-resource{
                  // background-color: #96e0ff;
                  background-color: #447df7;
                  span.slot-text{
                    color: #777777;
                  }
                  a.slot-text{
                    color: #777777;
                    cursor: auto;
                    &:hover{
                      text-decoration: none;
                    }
                  }
                }
                border-bottom: 1px solid #e9e9e9;
                &:last-child{
                  border-bottom: none;
                }
              }
            }
            // Table Header Left
            &:first-child{
              border: none!important;
            }
          }
        }
        .scheduler-view{
          border: none;
          & > div{
            .scheduler-content{
              .scheduler-content-table{
                tbody{
                  tr{
                    border-bottom: 1px solid #e9e9e9;
                    .dayresource {
                      border: 1px solid black;
                    }
                    .pending-row{
                      //background-color: #447df7 ;
                    }
                    .event-container{
                      cursor: pointer;
                      .timeline-event{
                        margin-top: 1px;
                        .event-item{
                          background-color: #447df7!important;
                          font-size: 11px!important;
                          border-radius: 20px!important;
                          border-left: 0!important;
                          padding: 0 5px!important;
                          text-align: center;
                          transition: opacity 0.3s ease-in-out;
                          span{
                            margin: 0!important;
                          }
                          &:hover{
                            opacity: 0.8;
                          }
                        }
                      }
                    }
                    .add-more-button{
                      .btn{
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        width: 20px;
                        height: 20px;
                        padding: 0 0 3px 1px;
                        color: black;
                        background-color: #fff;
                        border-color: black;
                        i{
                          font-size: 12px;
                        }
                      }
                    }
                    &:last-child{
                      border-bottom: none;
                    }
                  }
                }
              }
            }
            border: none;
            // Table Header Right
            &:first-child{
              & > div{
                padding-bottom: 17px;
              }
              .scheduler-bg-table{
                thead > tr > th {
                  font-size: 12px;
                 // color: #9A9A9A;
                  font-weight: 400;
                  border-right: 1px solid #e9e9e9;
                  border-bottom: 1px solid #e9e9e9;
                }
                thead > tr > td {
                  border-right: 1px solid #e9e9e9 !important;
                  border-bottom: 1px solid #e9e9e9 !important;
                }
              }
            }
          }
        }
        .scheduler-bg-table{
          tr > td {
            border-right: 1px solid #e9e9e9 !important;
          }
          .tr-0 td {
            background: #00bbff;
          }
        }
      }
    }
  }
  &-popup{
    max-width: 300px;
    &__header{
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      div{
        min-width: 10px;
        max-width: 10px;
        min-height: 10px;
        max-height: 10px;
        margin-right: 5px;
      }
      span{
        color: #447df7;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &__content{
      padding-top: 5px;
      border-top: 1px solid #e9e9e9;
      text-align: center;
      font-size: 14px;
      color: #9A9A9A;
    }
  }
}
